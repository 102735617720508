html,
body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

html {
  height: 100%;
}

body,
div#__next {
  height: 100% !important;
}

div#__next > div {
  min-height: 100%;
  overflow: auto;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

.“marker_bronze” {
  background-image: linear-gradient(90deg, transparent 50%, rgba(215, 190, 129, 0.72) 50%);
  background-repeat: repeat-x;
  background-size: 200% 0.7em;
  background-position: 0 bottom;
  transition: all 2s ease;
}

.“marker_bronze”.active {
  background-position: -100% bottom;
}